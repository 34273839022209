import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, of, switchMap } from "rxjs";
import {
  createOrganization,
  createOrganizationFailure,
  createOrganizationSuccess,
  deleteOrganization,
  deleteOrganizationFailure,
  deleteOrganizationSuccess,
  loadOrganizations,
  loadOrganizationsFailure,
  loadOrganizationsSuccess,
  selectOrganization,
  selectOrganizationFailure,
  selectOrganizationSuccess,
  updateOrganization,
  updateOrganizationFailure,
  updateOrganizationSuccess
} from "../actions";
import { OrganizationService } from "../../services";
import { Router } from "@angular/router";
import { NotificationService } from "src/app/core/services/notification/notification.service";

@Injectable()
export class OrganizationEffects {
  loadOrganizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOrganizations),
      concatMap(() =>
        this.organizationService.getOrganizations().pipe(
          map((organizations) => loadOrganizationsSuccess({ organizations: organizations })),
          catchError(() => of(loadOrganizationsFailure()))
        )
      )
    );
  });

  selectOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectOrganization),
      switchMap(({ organizationId }) =>
        this.router.navigate([`/organizations/${organizationId}`]).then(
          () => selectOrganizationSuccess({ organizationId }),
          () => selectOrganizationFailure()
        )
      )
    );
  });

  createOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createOrganization),
      concatMap(({ organization }) =>
        this.organizationService.createOrganization(organization).pipe(
          map((organization) => createOrganizationSuccess({ organization })),
          catchError(() => of(createOrganizationFailure()))
        )
      )
    );
  });

  deleteOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteOrganization),
      concatMap(({ organizationId }) =>
        this.organizationService.deleteOrganization(organizationId).pipe(
          map(() => deleteOrganizationSuccess({ organizationId })),
          catchError(() => of(deleteOrganizationFailure()))
        )
      )
    );
  });

  updateOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateOrganization),
      concatMap(({ organization }) =>
        this.organizationService.updateOrganization(organization).pipe(
          map((organization) => updateOrganizationSuccess({ organization })),
          catchError(() => of(updateOrganizationFailure()))
        )
      )
    );
  });

  notifyUpdateOrganizationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateOrganizationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.organization_update_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyCreateOrganizationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(createOrganizationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.organization_create_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyDeleteOrganizationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(deleteOrganizationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.organization_delete_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private router: Router,
    private notificationService: NotificationService
  ) {}
}
