<div
  class="container"
  *ngrxLet="{
    organization: organization$,
    hasRoleSwisscomHealthOrgAdminWrite: hasRoleSwisscomHealthOrgAdminWrite$
  } as ctx">
  <sdx-loading-spinner
    size="large"
    sr-hint="Loading. Please wait."
    *ngIf="!ctx.organization"></sdx-loading-spinner>
  <div class="row bg-gradient-1 padding-4 my-rounded-corner" *ngIf="ctx.organization">
    <div class="col-xs-12 col-md-8">
      <sdx-tag
        background="dark"
        label="{{ 'organization.header.label_tag' | translate }} ({{
          'data.organization_type.' + ctx.organization.type | lowercase | translate
        }})"
        icon-name="icon-office" />
      <h1 class="h1 white margin-bottom-0 margin-top-2">{{ ctx.organization.name }}</h1>
      <p *ngIf="ctx.organization.glns.length > 0" class="white margin-top-2">
        <b>{{ 'organization.header.label_glns' | translate }}</b> {{ toDisplayGln(ctx.organization.glns) }}
      </p>
    </div>
    <div class="col-xs-12 col-md-4 text-align-right">
      <sdx-button
        *ngIf="ctx.hasRoleSwisscomHealthOrgAdminWrite"
        label="{{ 'organization.header.button_edit' | translate }}"
        background="dark"
        icon-name="icon-edit"
        (click)="editMode = !editMode"></sdx-button>
    </div>
  </div>

  <div class="row" *ngIf="editMode && ctx.organization">
    <div class="col-xs-12 margin-top-2">
      <app-organization-form
        [showSave]="ctx.hasRoleSwisscomHealthOrgAdminWrite"
        [showCancel]="true"
        [organization]="ctx.organization"
        (saveEmitter)="saveOrganization($event)"
        (cancelEmitter)="this.editMode = !this.editMode"></app-organization-form>
    </div>
  </div>

  <div class="row" *ngIf="ctx.organization">
    <div class="col-xs-12 margin-top-4">
      <sdx-tabs theme="centered">
        <sdx-tabs-item
          label="{{ 'organization.info.label_tab' | translate }}"
          icon-name="icon-information-circle"
          [selected]="fragment === 'info'"
          [href]="getWindowLocation() + '#info'">
          <div class="container">
            <div class="row paragraph flex-items-xs-middle">
              <div class="col-xs-12 col-md-8 padding-3 ">
                <h1 class="h1">{{ "organization.info.getting_started_label" | translate }}</h1>
                <p>{{ "organization.info.getting_started_text" | translate }}</p>
              </div>
              <div class="col-xs-12 col-md-4">
                <img class="image" src="/assets/Illustration-356.svg" alt="Illustration of a rocket" />
              </div>
            </div>

            <div class="row row--gutters">
              <div class="col-xs-12 col-md-4 padding-3">
                <sdx-card
                  object-fit="contain"
                  label="{{ 'organization.info.locations_label' | translate }}"
                  label-aria-level="3"
                  image-src="/assets/Illustration-248.svg"
                  image-alt="Office"
                  [href]="getWindowLocation() + '#locations'"
                  [attr.href-label]="'organization.info.locations_href_label' | translate"
                  [attr.href-aria-label]="'organization.info.locations_href_label' | translate">
                  <p>{{ "organization.info.locations_description" | translate }}</p>
                </sdx-card>
              </div>
              <div class="col-xs-12 col-md-4 padding-3">
                <sdx-card
                  object-fit="contain"
                  label="{{ 'organization.info.ous_label' | translate }}"
                  label-aria-level="3"
                  image-src="/assets/Illustration-349.svg"
                  image-alt="Organizational Unit"
                  [href]="getWindowLocation() + '#ous'"
                  [attr.href-label]="'organization.info.ous_href_label' | translate"
                  [attr.href-aria-label]="'organization.info.ous_href_label' | translate">
                  <p>{{ "organization.info.ous_description" | translate }}</p>
                </sdx-card>
              </div>
              <div class="col-xs-12 col-md-4 padding-3">
                <sdx-card
                  object-fit="contain"
                  label="{{ 'organization.info.user_management_label' | translate }}"
                  label-aria-level="3"
                  image-src="/assets/Illustration-5.svg"
                  image-alt="People"
                  [href]="getWindowLocation() + '#userManagement'"
                  [attr.href-label]="'organization.info.user_management_href_label' | translate"
                  [attr.href-aria-label]="'organization.info.user_management_href_label' | translate">
                  <p>{{ "organization.info.user_management_description" | translate }}</p>
                </sdx-card>
              </div>
            </div>
          </div>
        </sdx-tabs-item>

        <sdx-tabs-item
          label="{{ 'organization.locations.label_tab' | translate }}"
          icon-name="icon-hospital-building"
          [selected]="fragment === 'locations'"
          [href]="getWindowLocation() + '#locations'">
          <app-locations [organizationId]="ctx.organization.id"></app-locations>
        </sdx-tabs-item>

        <sdx-tabs-item
          label="{{ 'organization.ous.label_tab' | translate }}"
          icon-name="icon-topology"
          [selected]="fragment === 'ous'"
          [href]="getWindowLocation() + '#ous'">
          <app-ous [organizationId]="ctx.organization.id"></app-ous>
        </sdx-tabs-item>

        <sdx-tabs-item
          label="{{ 'organization.user_management.label_tab' | translate }}"
          icon-name="icon-group"
          [selected]="fragment === 'userManagement'"
          [href]="getWindowLocation() + '#userManagement'">
          <app-user-management [organizationId]="ctx.organization.id"></app-user-management>
        </sdx-tabs-item>

        <sdx-tabs-item
          *ngIf="ctx.hasRoleSwisscomHealthOrgAdminWrite"
          label="{{ 'organization.issuers.label_tab' | translate }}"
          icon-name="icon-certificate"
          [selected]="fragment === 'issuers'"
          [href]="getWindowLocation() + '#issuers'">
          <app-issuers [organizationId]="ctx.organization.id"></app-issuers>
        </sdx-tabs-item>
      </sdx-tabs>
    </div>
  </div>
</div>
