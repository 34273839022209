import { Observable } from "rxjs";

export class LoginService {
  setup(): void {}

  logout(): Observable<void> {
    return new Observable<void>();
  }

  editProfile(): void {}

  resetPassword(loginHint?: string, state?: string): void {}

  manageTotp(loginHint?: string): void {}

  manageWebAuthn(): void {}
}
