import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { RoleScope } from "./role.state";

export interface HealthUser {
  id: string;
  objectId: string;
  mail: string;
  firstName: string;
  lastName: string;
  glns: string[];
}

export interface RoleAssignment {
  id: string;
  roleName: string;
  roleDisplayName: string;
  entityId: string;
  scope: RoleScope;
  assignee: HealthUser;
}

export interface RoleAssignmentState extends EntityState<RoleAssignment> {
  loading: boolean;
  loaded: boolean;
}

export const roleAssignmentAdapter: EntityAdapter<RoleAssignment> =
  createEntityAdapter<RoleAssignment>({
    selectId: (roleAssignment) => roleAssignment.id,
    sortComparer: (roleAssignment1, roleAssignment2) =>
      roleAssignment1.roleName.localeCompare(roleAssignment2.roleName)
  });
