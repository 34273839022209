import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Observable } from "rxjs";
import { selectCurrentOrganization } from "src/app/organizations/store";
import { Session } from "../../models";
import { selectSession } from "../../store";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {
  public session$: Observable<Session>;

  login: String = "{}";
  navigation: String = "{}";
  index: String = JSON.stringify({
    label: "Home",
    href: window.location.origin,
    ariaLabel: "Home",
    rel: "nofollow"
  });

  constructor(
    private translateService: TranslateService,
    private store: Store,
    private router: Router
  ) {
    this.session$ = this.store.select(selectSession);

    this.session$.subscribe((session) => {
      let givenName = session.givenName;
      let familyName = session.familyName;
      if (givenName && familyName) {
        this.buildLogin(session.isLoggedIn, givenName, familyName);
      } else {
        this.buildLogin(session.isLoggedIn, session.email);
      }
      this.buildNavigation(session.isLoggedIn);
    });
  }

  private buildNavigation(isLoggedIn: boolean) {
    combineLatest([
      this.store.select(selectCurrentOrganization),
      this.translateService.get([
        "header.main.label",
        "header.main.label_my_account",
        "header.main.label_organizations"
      ])
    ]).subscribe(([organization, results]) => {
      let navigation: any = {
        main: {
          label: results["header.main.label"],
          children: [
            {
              label: results["header.main.label_my_account"],
              href: "/my-account",
              active: window.location.pathname === "/my-account"
            },
            {
              label: results["header.main.label_organizations"],
              href: "/organizations",
              active: window.location.pathname === "/organizations",
              children: organization
                ? [
                    {
                      label: organization?.name,
                      active: true,
                      hidden: true
                    }
                  ]
                : []
            }
          ]
        }
      };

      this.navigation = JSON.stringify(navigation);
    });
  }

  private buildLogin(isLoggedIn: boolean, givenName = "", familyName = "") {
    this.translateService
      .get(["header.label_login", "header.label_logout"])
      .subscribe((translations) => {
        if (isLoggedIn) {
          this.login = JSON.stringify({
            slot: "login",
            customer: {
              fullName: `${givenName} ${familyName}`
            },
            logout: {
              label: translations["header.label_logout"],
              href: "/logout"
            }
          });
        } else {
          this.login = "";
        }
      });
  }
}
