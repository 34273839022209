import { Component, Input } from "@angular/core";
import { combineLatest, map, Observable, startWith } from "rxjs";
import { Store } from "@ngrx/store";
import {
  createLocation,
  deleteLocation,
  selectAllLocations,
  selectLocationsLoading,
  updateLocation
} from "../../store";
import { Location, LocationType } from "../../models";
import { CountryService } from "../../../core/services";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"]
})
export class LocationsComponent {
  @Input() organizationId!: string;

  locations$: Observable<Location[]>;
  loading$: Observable<boolean>;
  locationBeingEdited: Location | undefined;
  searchControl: FormControl = new FormControl("");
  allLocations$: Observable<Location[]>;

  constructor(
    private store: Store,
    private countriesService: CountryService
  ) {
    this.allLocations$ = this.store.select(selectAllLocations);
    this.loading$ = this.store.select(selectLocationsLoading);

    const searchValue$ = this.searchControl.valueChanges.pipe(
      startWith("") // Provide an initial value
    );

    this.locations$ = combineLatest([this.allLocations$, searchValue$]).pipe(
      map(([locations, searchValue]) =>
        locations.filter(
          (location) =>
            location.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            location.city.toLowerCase().includes(searchValue.toLowerCase()) ||
            location.zipCode.toLowerCase().includes(searchValue.toLowerCase()) ||
            location.addressLine1.toLowerCase().includes(searchValue.toLowerCase()) ||
            location.addressLine2.toLowerCase().includes(searchValue.toLowerCase())
        )
      )
    );
  }

  editLocation(location: Location) {
    this.locationBeingEdited = location;
  }

  deleteLocation(location: Location) {
    this.store.dispatch(deleteLocation({ location }));
  }

  initializeNewLocation() {
    this.editLocation({
      id: "",
      parentOrganizationId: this.organizationId,
      childOrganizationalUnitIds: [],
      name: "",
      type: LocationType.OTHER,
      city: "",
      zipCode: "",
      addressLine1: "",
      addressLine2: "",
      country: ""
    });
  }

  save(location: Location) {
    this.store.dispatch(
      this.locationBeingEdited?.id ? updateLocation({ location }) : createLocation({ location })
    );
    this.locationBeingEdited = undefined;
  }

  cancel() {
    this.locationBeingEdited = undefined;
  }

  getCountryName(countryCode: string) {
    return this.countriesService.getCountryName(countryCode);
  }
}
