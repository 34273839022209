<div class="container" *ngrxLet="ous$ as ous">
  <div class="row flex-items-xs-center">
    <h5 class="navy">{{ "organization.ous.description" | translate }}</h5>
  </div>

  <div class="row" *ngIf="(allOus$ | async)?.length === 0">
    <div class="col-xs-12 margin-top-2">
      <sdx-card
        layout="notification"
        icon-name="icon-information-circle"
        label="{{ 'organization.ous.empty_help' | translate }}"
        label-aria-level="3">
      </sdx-card>
    </div>
  </div>

  <div class="row">
    <div class="col-xs margin-top-2">
      <sdx-input
        id="search"
        type="search"
        placeholder="{{ 'organization.ous.search_placeholder' | translate }}"
        sr-hint="{{ 'organization.ous.search_placeholder' | translate }}"
        sr-hint-search-button="Search"
        [formControl]="searchControl"
        ngDefaultControl />
    </div>
    <div class="col-xs-12 col-md-auto margin-top-2">
      <sdx-button-group layout="responsive-center">
        <sdx-button
          icon-name="icon-plus"
          label="{{ 'organization.ous.button_add_ou' | translate }}"
          [attr.disabled]="ouBeingEdited"
          (click)="initializeNewOu()"></sdx-button>
      </sdx-button-group>
    </div>

    <div *ngIf="ouBeingEdited" class="col-xs-12 margin-top-2">
      <app-ou-form
        [ou]="ouBeingEdited"
        (saveEmitter)="save($event)"
        (cancelEmitter)="cancel()">
      </app-ou-form>
    </div>

    <div class="col-xs-12 margin-top-2">
      <div id="ous-table" class="table table--responsive table--highlight">
        <div class="table__wrapper">
          <table>
            <thead>
              <tr>
                <th data-type="text">{{ "organization.ous.table_label_name" | translate }}</th>
                <th data-type="text">{{ "organization.ous.table_label_glns" | translate }}</th>
                <th data-type="text">{{ "organization.ous.table_label_location" | translate }}</th>
                <th data-type="text" class="text-align-right">
                  {{ "organization.ous.table_label_actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="ous.length === 0">
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td class="text-align-right">-</td>
              </tr>
              <tr *ngFor="let ou of ous">
                <td>{{ ou.name }}</td>
                <td>{{ ou.glns }}</td>
                <td *ngrxLet="locationEntities$ as locationEntities">
                  {{ locationEntities[ou.parentLocationId]?.name }}
                </td>
                <td class="text-align-right margin-0 padding-0">
                  <div class="toolbar" id="toolbarOu">
                    <button class="toolbar__item item--show" aria-label="Edit" (click)="editOu(ou)">
                      <i class="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <sdx-dialog
                      label="{{ 'organization.ous.modal_label' | translate }}"
                      #deleteOuModal
                      icon-name="icon-bin"
                      align-header-center
                    >
                      <sdx-dialog-toggle>
                        <button
                          class="toolbar__item item--show"
                          aria-label="Delete">
                          <i class="icon icon-bin" aria-hidden="true"></i>
                        </button>
                      </sdx-dialog-toggle>

                      <sdx-dialog-content>
                        <p class="text-align-center margin-bottom-4">
                          {{ 'organization.ous.modal_dialog_1' | translate }}<b>{{ou.name}}</b>{{ 'organization.ous.modal_dialog_2' | translate }}
                        </p>
                        <p>
                          <sdx-button-group layout="responsive-center">
                            <sdx-button id="close-modal" label="{{ 'organization.ous.modal_button_delete' | translate }}" (click)="deleteOu(ou); deleteOuModal.close()"></sdx-button>
                            <sdx-button id="first-action-element" label="{{ 'organization.ous.modal_button_keep' | translate }}" (click)="deleteOuModal.close()" theme="secondary"></sdx-button>
                          </sdx-button-group>
                        </p>
                      </sdx-dialog-content>
                    </sdx-dialog>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row flex-items-xs-center margin-top-2" *ngIf="loading$ | async">
    <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
  </div>
</div>
