<div
  class="container"
  *ngrxLet="{
    invitations: invitations$,
    invitationLoading: invitationLoading$
  } as ctx">
  <div class="row">
    <h2 class="navy">{{ "my_account.invitations.header" | translate }}</h2>
  </div>

  <div class="row flex-items-xs-center">
    <h5 class="navy">{{ "my_account.invitations.description" | translate }}</h5>
  </div>
  <div class="row" *ngIf="ctx.invitations.length === 0">
    <div class="col-xs-12 padding-left-0 margin-top-2">
      <sdx-card
        layout="notification"
        icon-name="icon-information-circle"
        label="{{ 'my_account.invitations.no_invitations_body' | translate }}"
        label-aria-level="3">
      </sdx-card>
    </div>
  </div>

  <div *ngIf="ctx.invitationLoading" class="row">
    <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
  </div>

  <br />
  <div class="row" *ngIf="ctx.invitations.length > 0 && !ctx.invitationLoading">
    <div class="col-xs-12 padding-left-0">
      <div id="invitations-table" class="table table--responsive table--highlight">
        <div class="table__wrapper">
          <table>
            <thead>
              <tr>
                <th data-type="text">
                  {{ "my_account.invitations.table_label_invitee_mail_address" | translate }}
                </th>
                <th data-type="text">
                  {{ "my_account.invitations.table_label_scope" | translate }}
                </th>
                <th data-type="text">
                  {{ "my_account.invitations.table_label_role_name" | translate }}
                </th>
                <th data-type="text" class="text-align-right">
                  {{ "my_account.invitations.table_label_actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invitation of ctx.invitations">
                <td>{{ invitation.inviteeMailAddress }}</td>
                <td>
                  {{ invitation.entityName }}
                  <i>({{ "data.role_scope." + invitation.scope | lowercase | translate }})</i>
                </td>
                <td>{{ invitation.roleDisplayName }}</td>
                <td class="text-align-right margin-0 padding-0">
                  <div class="toolbar" id="toolbarLocation">
                    <button
                      class="toolbar__item item--show"
                      aria-label="Accept"
                      (click)="acceptMyInvitation(invitation.id)">
                      <i
                        class="icon icon-check-mark-2-circle int-green--active"
                        aria-hidden="true"></i>
                      <span class="toolbar__label">{{
                        "my_account.invitations.table_action_accept" | translate
                      }}</span>
                    </button>
                    <button
                      class="toolbar__item item--show"
                      aria-label="Decline"
                      (click)="declineMyInvitation(invitation.id)">
                      <i class="icon icon-cancel int-red--active" aria-hidden="true"></i>
                      <span class="toolbar__label">{{
                        "my_account.invitations.table_action_decline" | translate
                      }}</span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
