import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { RoleScope } from "./role.state";

export interface Invitation {
  id: string;
  inviteeMailAddress: string;
  roleName: string;
  roleDisplayName: string;
  scope: RoleScope;
  entityId: string;
  entityName: string;
}

export interface InvitationState extends EntityState<Invitation> {
  loading: boolean;
  loaded: boolean;
}

export const invitationAdapter: EntityAdapter<Invitation> = createEntityAdapter<Invitation>({
  selectId: (invitation) => invitation.id,
  sortComparer: (invitation1, invitation2) =>
    invitation1.inviteeMailAddress.localeCompare(invitation2.inviteeMailAddress)
});
