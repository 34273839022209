import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { combineLatest, map, Observable, of, startWith } from "rxjs";
import { Invitation, RoleAssignment, RoleScope } from "../../models";
import {
  createInvitation,
  deleteInvitation,
  deleteRoleAssignment,
  selectAllInvitations,
  selectAllRoleAssignments,
  selectInvitationsLoading,
  selectLocationNameById,
  selectOrganizationNameById,
  selectOuNameById,
  selectRoleAssignmentsLoading
} from "../../store";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserManagementComponent {
  roleAssignments$: Observable<RoleAssignment[]>;
  invitations$: Observable<Invitation[]>;
  allRoleAssignments$: Observable<RoleAssignment[]>;
  allInvitations$: Observable<Invitation[]>;
  invitationsLoading$: Observable<boolean>;
  roleAssignmentsLoading$: Observable<boolean>;

  // scopeFilter: RoleScope[] = [];
  // statusFilter: ("accepted" | "invited")[] = [];

  invitationBeingEdited: Invitation | undefined;
  searchControl: FormControl = new FormControl("");
  scopeControl: FormControl = new FormControl("");
  statusControl: FormControl = new FormControl("");

  protected readonly RoleScope = RoleScope;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {
    this.allRoleAssignments$ = this.store.select(selectAllRoleAssignments);
    this.allInvitations$ = this.store.select(selectAllInvitations);

    const searchValue$ = this.searchControl.valueChanges.pipe(
      startWith('') // Provide an initial value
    );
    const scopeValue$ = this.scopeControl.valueChanges.pipe(
      startWith([]) // Provide an initial value
    );
    const statusValue$ = this.statusControl.valueChanges.pipe(
      startWith([]) // Provide an initial value
    );

    this.roleAssignments$ = combineLatest([this.allRoleAssignments$, searchValue$, scopeValue$, statusValue$]).pipe(
      map(([roleAssignments, searchValue, scopeValue, statusValue]) =>
        roleAssignments.filter((roleAssignment) => {
          const searchMatches =
            roleAssignment.roleName.toLowerCase().includes(searchValue.toLowerCase()) ||
            roleAssignment.assignee.mail.toLowerCase().includes(searchValue.toLowerCase()) ||
            roleAssignment.assignee.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
            roleAssignment.assignee.lastName.toLowerCase().includes(searchValue.toLowerCase());
          const scopeFilterMatches = scopeValue.length === 0 || scopeValue.includes(roleAssignment.scope);
          const statusFilterMatches = statusValue.length === 0 || statusValue.includes('accepted');
          return searchMatches && scopeFilterMatches && statusFilterMatches;
        })
      )
    );

    this.invitations$ = combineLatest([this.allInvitations$, searchValue$, scopeValue$, statusValue$]).pipe(
      map(([invitations, searchValue, scopeValue, statusValue]) =>
        invitations.filter((invitation) => {
          const searchMatches =
            invitation.roleName.toLowerCase().includes(searchValue.toLowerCase()) ||
            invitation.inviteeMailAddress.toLowerCase().includes(searchValue.toLowerCase());
          const scopeFilterMatches = scopeValue.length === 0 || scopeValue.includes(invitation.scope);
          const statusFilterMatches = statusValue.length === 0 || statusValue.includes('invited');
          console.log("searchMatches", searchMatches);
          console.log("scopeFilterMatches", scopeFilterMatches);
          console.log("statusFilterMatches", statusFilterMatches);

          return searchMatches && scopeFilterMatches && statusFilterMatches;
        })
      )
    );

    this.invitationsLoading$ = this.store.select(selectInvitationsLoading);
    this.roleAssignmentsLoading$ = this.store.select(selectRoleAssignmentsLoading);
  }

  cancelInvite() {
    this.invitationBeingEdited = undefined;
  }

  newInvite() {
    this.invitationBeingEdited = {
      id: "",
      inviteeMailAddress: "",
      roleName: "",
      roleDisplayName: "",
      scope: RoleScope.ORG,
      entityId: "",
      entityName: ""
    };
  }

  saveInvitation(invitation: Invitation) {
    this.invitationBeingEdited = undefined;
    this.store.dispatch(createInvitation({ invitation }));
  }

  deleteRoleAssignment(roleAssignment: RoleAssignment) {
    this.store.dispatch(deleteRoleAssignment({ roleAssignment }));
  }

  deleteInvitation(invitation: Invitation) {
    this.store.dispatch(deleteInvitation({ invitation }));
  }

  getEntityNameByScopeAndName(scope: RoleScope, entityId: string): Observable<string> {
    if (scope === RoleScope.ORG) {
      return this.store.select(selectOrganizationNameById(entityId));
    } else if (scope === RoleScope.LOC) {
      return this.store.select(selectLocationNameById(entityId));
    } else if (scope === RoleScope.OU) {
      return this.store.select(selectOuNameById(entityId));
    } else return of("");
  }

  getIconNameForScope(scope: RoleScope): string {
    switch (scope) {
      case RoleScope.ORG:
        return "icon-office";
      case RoleScope.LOC:
        return "icon-hospital-building";
      case RoleScope.OU:
        return "icon-topology";
    }
  }
}
