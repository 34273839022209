<div class="container">
  <div class="row flex-items-xs-center">
    <div class="col-xs-auto">
      <h1 class="navy">{{"logout.label_logging_out" | translate}}</h1>
    </div>
  </div>
  <div class="row flex-items-xs-center">
    <div class="col-xs-auto">
      <sdx-loading-spinner size="large" sr-hint="{{'logout.label_logging_out' | translate}}"></sdx-loading-spinner>
    </div>
  </div>
</div>
