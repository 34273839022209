<div class="container" *ngrxLet="session$ as session">
  <div class="row bg-gradient-1 padding-4 my-rounded-corner margin-bottom-4">
    <div class="col-xs-12">
      <h1 class="h1 white margin-2">{{ "header.main.label_my_account" | translate }}</h1>
    </div>
  </div>

  <div class="row margin-top-2">
    <app-my-invitations></app-my-invitations>
  </div>

  <div class="row margin-top-2">
    <h2 class="navy">{{ "my_account.self_services.header" | translate }}</h2>
  </div>

  <div class="row flex-items-xs-center">
    <h5 class="navy">{{ "my_account.self_services.description" | translate }}</h5>
  </div>

  <div class="row row--gutters margin-top-2">
    <div class="col-xs-12 col-md-6">
      <sdx-card
        size="small"
        layout="interaction"
        icon-name="icon-edit"
        [attr.label]="'my_account.self_services.edit_profile_card.label' | translate"
        label-aria-level="3"
        [attr.href-label]="'my_account.self_services.edit_profile_card.description' | translate"
        href="javascript:;"
        (click)="editProfile()"></sdx-card>
    </div>
    <div class="col-xs-12 col-md-6">
      <sdx-card
        size="small"
        layout="interaction"
        icon-name="icon-security"
        [attr.label]="
          session?.hasPassword
            ? ('my_account.self_services.reset_password_card.label' | translate)
            : ('my_account.self_services.set_password_card.label' | translate)
        "
        label-aria-level="3"
        [attr.href-label]="
          session?.hasPassword
            ? ('my_account.self_services.reset_password_card.description' | translate)
            : ('my_account.self_services.set_password_card.description' | translate)
        "
        href="javascript:;"
        (click)="resetPassword(session.email)"></sdx-card>
    </div>

    <div class="col-xs-12 col-md-6" *ngIf="session?.hasPassword">
      <sdx-card
        size="small"
        layout="interaction"
        icon-name="icon-phone-security"
        [attr.label]="'my_account.self_services.totp_card.label' | translate"
        label-aria-level="3"
        [attr.href-label]="'my_account.self_services.totp_card.description' | translate"
        href="javascript:;"
        (click)="manageTotp()"></sdx-card>
    </div>
    <div class="col-xs-12 col-md-6">
      <sdx-card
        size="small"
        layout="interaction"
        icon-name="icon-key"
        [attr.label]="
          session?.webauthnRegistered
            ? ('my_account.self_services.fido2_reset_card.label' | translate)
            : ('my_account.self_services.fido2_register_card.label' | translate)
        "
        label-aria-level="3"
        [attr.href-label]="
          session?.webauthnRegistered
            ? ('my_account.self_services.fido2_reset_card.description' | translate)
            : ('my_account.self_services.fido2_register_card.description' | translate)
        "
        href="javascript:;"
        (click)="manageWebAuthn()"></sdx-card>
    </div>
  </div>
</div>
