import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { combineLatest, filter, map, Observable, startWith, tap } from "rxjs";
import { selectIsSessionSwisscomHealthOrgAdminWrite } from "src/app/core/store/selectors";
import { NotificationService } from "../../../core/services";
import { Organization, OrganizationType } from "../../models";
import { ImportService } from "../../services/import.service";
import {
  createOrganization,
  deleteOrganization,
  loadOrganizations,
  selectAllOrganizations,
  selectOrganization,
  selectOrganizationsLoading
} from "../../store";

@Component({
  selector: "app-organizations",
  templateUrl: "./organizations.component.html",
  styleUrls: ["./organizations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationsComponent {
  organizations$: Observable<Organization[]>;
  allOrganizations$: Observable<Organization[]>;
  loadingOrganization$: Observable<boolean>;
  isSessionSwisscomHealthOrgAdminWrite$: Observable<boolean>;

  organizationBeingEdited: Organization | undefined;
  searchControl: FormControl = new FormControl("");

  @ViewChild("orgImportFileInput") orgImportFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild("invitationImportFileInput") invitationImportFileInput!: ElementRef<HTMLInputElement>;
  protected readonly ImportType = ImportType;

  constructor(
    private store: Store,
    private importService: ImportService,
    private notificationService: NotificationService
  ) {
    this.allOrganizations$ = this.store.select(selectAllOrganizations);
    
    const searchValue$ = this.searchControl.valueChanges.pipe(
      startWith("") // Provide an initial value
    );

    this.organizations$ = combineLatest([this.allOrganizations$, searchValue$]).pipe(
      map(([organizations, searchValue]) =>
        organizations.filter((org) => 
          org.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          org.glns.some(gln => gln.toLowerCase().includes(searchValue.toLowerCase()))
        )
      )
    );

    this.loadingOrganization$ = this.store.select(selectOrganizationsLoading);
    this.isSessionSwisscomHealthOrgAdminWrite$ = this.store.select(
      selectIsSessionSwisscomHealthOrgAdminWrite
    );
  }

  initializeNewOrganization() {
    this.organizationBeingEdited = {
      childLocationIds: [],
      glns: [],
      type: OrganizationType.OTHER,
      id: "",
      name: ""
    };
  }

  save(organization: Organization) {
    this.store.dispatch(createOrganization({ organization }));
    this.organizationBeingEdited = undefined;
  }

  cancel() {
    this.organizationBeingEdited = undefined;
  }

  selectOrganization(organizationId: string) {
    this.store.dispatch(selectOrganization({ organizationId }));
  }

  deleteOrganization(organizationId: string) {
    this.store.dispatch(deleteOrganization({ organizationId }));
  }

  openImportOrganizationsFileDialog() {
    this.orgImportFileInput.nativeElement.click();
  }

  openImportInvitationsFileDialog() {
    this.invitationImportFileInput.nativeElement.click();
  }

  importFromFile($event: Event, importType: ImportType) {
    const file = ($event.target as HTMLInputElement).files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        let importJson = reader.result as string;
        let importResult =
          importType === ImportType.ORGANIZATION
            ? this.importService.importOrganizations(importJson)
            : this.importService.importInvitations(importJson);

        importResult
          .pipe(
            tap((response) => {
              this.download(response);

              if (response.errors.length > 0) {
                this.notificationService.showTranslatedNotification(
                  "notifications.organizations_import_failed",
                  "warning"
                );
              } else if (response.successfulImportsCount === 0) {
                this.notificationService.showTranslatedNotification(
                  "notifications.organizations_import_nothing_imported",
                  "general"
                );
              } else {
                this.notificationService.showTranslatedNotification(
                  "notifications.organizations_import_success",
                  "confirmation"
                );
              }
              this.store.dispatch(loadOrganizations());
            })
          )
          .subscribe();
      };
      reader.readAsText(file);
    }

    // Clear the file input value to ensure the change event is triggered even if the same file is selected again
    this.orgImportFileInput.nativeElement.value = "";
    this.invitationImportFileInput.nativeElement.value = "";
  }

  private download(content: any) {
    const blob = new Blob([JSON.stringify(content)], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "import_report.json";
    a.click();
    window.URL.revokeObjectURL(url);
  }
}

enum ImportType {
  ORGANIZATION = "organizations",
  INVITATION = "invitations"
}
