import { createSelector } from "@ngrx/store";

import { MyOrganizationState, roleAdapter, RoleScope, RoleState } from "../../models";
import { selectMyOrganizationsState } from "../reducers";

const { selectAll } = roleAdapter.getSelectors();

export const selectRolesState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.roles as RoleState
);

export const selectAllRoles = createSelector(selectRolesState, selectAll);
