import { Component, OnInit } from "@angular/core";
import { Organization } from "../../models";
import { Store } from "@ngrx/store";
import {
  selectCurrentOrganization,
  selectOrganizationsLoading,
  updateOrganization
} from "../../store";
import { Observable } from "rxjs";
import { selectIsSessionSwisscomHealthOrgAdminWrite } from "../../../core/store";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.scss"]
})
export class OrganizationComponent implements OnInit {
  fragment: string | null = "details";

  organization$: Observable<Organization | undefined>;
  loading$: Observable<boolean>;
  hasRoleSwisscomHealthOrgAdminWrite$: Observable<boolean>;
  editMode = false

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.hasRoleSwisscomHealthOrgAdminWrite$ = this.store.select(
      selectIsSessionSwisscomHealthOrgAdminWrite
    );
    this.organization$ = this.store.select(selectCurrentOrganization);
    this.loading$ = this.store.select(selectOrganizationsLoading);
  }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  saveOrganization(organization: Organization) {
    this.store.dispatch(updateOrganization({ organization }));
    this.editMode = !this.editMode
  }

  getWindowLocation() {
    return this.location.path();
  }

  toDisplayGln(glns: string[]) {
    if(glns.length == 0) return "-"; else return glns.join(", ");
  }
}
