import { createSelector } from "@ngrx/store";

import { MyOrganizationState, roleAssignmentAdapter, RoleAssignmentState } from "../../models";
import { roleAssignmentsReducer, selectMyOrganizationsState } from "../reducers";

const { selectAll } = roleAssignmentAdapter.getSelectors();

export const selectRoleAssignmentsState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.roleAssignments as RoleAssignmentState
);

export const selectRoleAssignmentsLoading = createSelector(
  selectRoleAssignmentsState,
  roleAssignmentsReducer.getRoleAssignmentsLoading
);

export const selectAllRoleAssignments = createSelector(selectRoleAssignmentsState, selectAll);
