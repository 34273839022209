<sdx-card label="{{ 'invitation_form.label' | translate }}" label-aria-level="2" background="grey">
  <div class="container-fluid" [formGroup]="invitationFormGroup">
    <div class="row">
      <div class="col-xs-12 col-lg-6 padding-top-2">
        <sdx-select
          formControlName="inviteeMailAddress"
          ngDefaultControl
          required="true"
          [valid]="invitationFormGroup.controls['inviteeMailAddress'].valid"
          label="{{ 'invitation_form.input_label_email' | translate }}"
          placeholder="{{ 'invitation_form.input_placeholder_email' | translate }}"
          filter-min-length="1"
          keyboard-behavior="autocomplete">
          <sdx-select-option *ngFor="let employeeMail of mails$ | async"
            >{{ employeeMail }}
          </sdx-select-option>
        </sdx-select>
      </div>

      <div class="col-xs-6 col-lg-3 padding-top-2">
        <sdx-select
          formControlName="scopeAndEntity"
          ngDefaultControl
          required="true"
          [valid]="invitationFormGroup.controls['scopeAndEntity'].valid"
          keyboard-behavior="filter"
          label="{{ 'invitation_form.input_label_scope' | translate }}"
          placeholder="{{ 'invitation_form.input_label_scope' | translate }}"
          filter-min-length="2">
          <sdx-select-optgroup name="{{ 'data.role_scope.org' | translate }}">
            <sdx-select-option
              *ngrxLet="currentOrganization$ as org"
              [value]="{ scope: RoleScope.ORG, entityId: org!.id }"
              >{{ org!.name }}
            </sdx-select-option>
          </sdx-select-optgroup>
          <sdx-select-optgroup name="{{ 'data.role_scope.loc' | translate }}">
            <sdx-select-option
              *ngFor="let location of (namedEntitiesByScope$ | async)!.locations"
              [value]="{ scope: RoleScope.LOC, entityId: location.id }"
              >{{ location.name }}
            </sdx-select-option>
          </sdx-select-optgroup>
          <sdx-select-optgroup name="{{ 'data.role_scope.ou' | translate }}">
            <sdx-select-option
              *ngFor="let ou of (namedEntitiesByScope$ | async)!.ous"
              [value]="{ scope: RoleScope.OU, entityId: ou.id }"
              >{{ ou.name }}</sdx-select-option
            >
          </sdx-select-optgroup>
        </sdx-select>
      </div>

      <div class="col-xs-6 col-lg-3 padding-top-2">
        <sdx-select
          formControlName="role"
          ngDefaultControl
          required="true"
          [valid]="invitationFormGroup.controls['role'].valid"
          keyboard-behavior="filter"
          label="{{ 'invitation_form.input_label_role' | translate }}"
          placeholder="{{ 'invitation_form.input_label_role' | translate }}"
          filter-min-length="2">
          <sdx-select-option
            *ngFor="let role of (availableRoles$ | async)!"
            value="{{ role.name }}"
            >{{ role.displayName }}</sdx-select-option
          >
        </sdx-select>
      </div>

      <div class="col-xs-12 padding-top-2 padding-bottom-2">
        <sdx-button-group>
          <sdx-button
            label="{{ 'invitation_form.button_cancel' | translate }}"
            theme="secondary"
            (click)="cancel()"></sdx-button>
          <sdx-button
            icon-name="icon-send"
            label="{{ 'invitation_form.button_send' | translate }}"
            theme="primary"
            (click)="save()"></sdx-button>
        </sdx-button-group>
      </div>
    </div>
  </div>
</sdx-card>
