import { NgOptimizedImage } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors
} from "@angular/common/http";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LetDirective } from "@ngrx/component";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent, containers } from "./core/containers";
import { httpInterceptor } from "./core/interceptors/http/http.interceptor";
import { LoginService } from "./core/services";
import { CustomSerializer, effects, reducers } from "./core/store";
import { MyAccountModule } from "./my-account/my-account.module";
import { OrganizationsModule } from "./organizations/organizations.module";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initLoginService(loginService: LoginService) {
  return () => loginService.setup();
}

export function initTranslationService(translateService: TranslateService) {
  return () => {
    let defaultLang = translateService.getBrowserLang() ?? "de";

    translateService.addLangs(["en", "de", "fr", "it"]);
    translateService.defaultLang = defaultLang;
    translateService.use(defaultLang);
  };
}

@NgModule({
  declarations: [containers],
  imports: [
    // App imports
    AppRoutingModule,
    MyAccountModule,
    OrganizationsModule,

    // Angular imports
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LetDirective,
    NgOptimizedImage,

    // Core NgRx setup
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),

    // Register the NgRx feature's reducer and effects
    StoreModule.forFeature("core", reducers),
    EffectsModule.forFeature(effects),

    // Environment specific imports
    environment.imports
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initTranslationService,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLoginService,
      deps: [LoginService],
      multi: true
    },    
    provideHttpClient(withInterceptors([httpInterceptor])),
    Title,
    environment.providers
  ],
  bootstrap: [AppComponent, environment.bootstrap],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
