import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { Organization } from "../../models";
import { OrganizationType } from "../../models/";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectOrganizationsLoading } from "../../store";
import { selectIsSessionSwisscomHealthOrgAdminWrite } from "src/app/core/store/selectors";
import { VALIDATOR_GTIN_13_LIST, VALIDATOR_NAME } from "src/app/core/util";

@Component({
  selector: "app-organization-form",
  templateUrl: "./organization-form.component.html",
  styleUrls: ["./organization-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationFormComponent implements OnInit {
  hasChanges = false;
  isLoading$: Observable<boolean>;
  isSwisscomHealthOrgAdminWrite$: Observable<boolean>;

  organizationFormGroup!: FormGroup;
  OrganizationType = OrganizationType;

  @Input() organization!: Organization;
  @Input() showCancel = true;
  @Input() showSave = true;
  @Input() label!: string;
  @Output() saveEmitter = new EventEmitter<Organization>();
  @Output() cancelEmitter = new EventEmitter<void>();

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) {
    this.isLoading$ = this.store.select(selectOrganizationsLoading);
    this.isSwisscomHealthOrgAdminWrite$ = this.store.select(
      selectIsSessionSwisscomHealthOrgAdminWrite
    );
  }

  ngOnInit() {
    this.label = this.organization.id
      ? "organization_form.title_edit"
      : "organization_form.title_new";
    this.initializeOrganization(this.organization);
  }

  save() {
    if (!this.organizationFormGroup.valid) {
      return;
    }

    let glnsInput = this.organizationFormGroup.controls["glns"].value as string;
    let glns: string[] = !glnsInput || glnsInput.trim() === "" ? [] : glnsInput.split(",");

    let organization: Organization = {
      id: this.organization.id,
      childLocationIds: this.organization.childLocationIds,
      name: this.organizationFormGroup.controls["name"].value,
      type: this.organizationFormGroup.controls["type"].value[0],
      glns: glns
    };

    this.saveEmitter.emit(organization);
    this.hasChanges = false;
  }

  cancel() {
    this.hasChanges = false;
    this.initializeOrganization(this.organization);
    this.cancelEmitter.emit();
  }

  initializeOrganization(organization: Organization) {
    this.organizationFormGroup = this.fb.group({
      id: new FormControl(organization.id),
      name: new FormControl(organization.name, [Validators.required, VALIDATOR_NAME]),
      glns: new FormControl(organization.glns.join(","), VALIDATOR_GTIN_13_LIST),
      type: new FormControl([organization.type], Validators.required)
    });

    this.disableFormIfNotSwisscomHealthOrgAdminWrite();
    this.organizationFormGroup.valueChanges.subscribe(
      (hasChanges) => (this.hasChanges = hasChanges)
    );
  }

  disableFormIfNotSwisscomHealthOrgAdminWrite() {
    if (!this.isSwisscomHealthOrgAdminWrite$) {
      this.organizationFormGroup.disable();
    } else {
      this.organizationFormGroup.enable();
    }
  }

  isReadonly(): boolean {
    return !this.isSwisscomHealthOrgAdminWrite$;
  }
}
