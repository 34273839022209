import { createAction, props } from "@ngrx/store";
import { Organization } from "../../models";

export const loadOrganizations = createAction("[Organization/API] Load Organizations");
export const loadOrganizationsSuccess = createAction(
  "[Organization/API] Load Organizations Success",
  props<{ organizations: Organization[] }>()
);
export const loadOrganizationsFailure = createAction(
  "[Organization/API] Load Organizations Failure"
);

export const updateOrganization = createAction(
  "[Organization/API] Update Organization",
  props<{ organization: Organization }>()
);
export const updateOrganizationSuccess = createAction(
  "[Organization/API] Update Organization Success",
  props<{ organization: Organization }>()
);
export const updateOrganizationFailure = createAction(
  "[Organization/API] Update Organization Failure"
);

export const selectOrganization = createAction(
  "[Organization/Page] Select Organization",
  props<{ organizationId: string }>()
);
export const selectOrganizationSuccess = createAction(
  "[Organization/Page] Select Organization Success",
  props<{ organizationId: string }>()
);
export const selectOrganizationFailure = createAction(
  "[Organization/Page] Select Organization Failure"
);

export const createOrganization = createAction(
  "[Organization/API] Create Organization",
  props<{ organization: Organization }>()
);
export const createOrganizationSuccess = createAction(
  "[Organization/API] Create Organization Success",
  props<{ organization: Organization }>()
);
export const createOrganizationFailure = createAction(
  "[Organization/API] Create Organization Failure"
);

export const deleteOrganization = createAction(
  "[Organization/API] Delete Organization",
  props<{ organizationId: string }>()
);
export const deleteOrganizationSuccess = createAction(
  "[Organization/API] Delete Organization Success",
  props<{ organizationId: string }>()
);
export const deleteOrganizationFailure = createAction(
  "[Organization/API] Delete Organization Failure"
);
