import * as MSAL from "@azure/msal-angular";
import { MsalGuard, MsalRedirectComponent } from "@azure/msal-angular";
import { MsalLoginService } from "../app/core/services/login/msal-login.service";
import { LoginService } from "../app/core/services";
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory
} from "../app/core/util/msal-helper";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

export const environment = {
  authGuards: [MsalGuard],
  imports: [],
  providers: [
    {
      provide: LoginService,
      useClass: MsalLoginService
    },
    {
      provide: MSAL.MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL.MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL.MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MSAL.MsalInterceptor,
      multi: true
    },
    MSAL.MsalService,
    MSAL.MsalGuard,
    MSAL.MsalBroadcastService
  ],
  bootstrap: [MsalRedirectComponent],
  production: true,
  b2c: {
    policyNames: {
      signUpSignIn: "B2C_1A_HealthPortal",
      resetPassword: "B2C_1A_Reset_Password",
      editProfile: "B2C_1A_Edit_Profile",
      manageTotp: "B2C_1A_Manage_Totp",
      manageWebAuthn: "B2C_1A_Manage_Webauthn"
    },
    clientId: "{{B2C_CLIENT_ID}}",
    tenantId: "{{B2C_TENANT_ID}}",
    authorityDomain: "{{AUTHORITY_DOMAIN}}"
  },
  api: {
    uri: "{{PORTAL_FUNCTIONS_ENDPOINT}}",
    scopes: [`https://{{AUTHORITY_DOMAIN}}/HealthPortalFunctions/HealthPortal.ReadWrite`]
  }
};
