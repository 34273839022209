<sdx-card label="{{ label | translate }}" background="grey">
  <div
    id="testId"
    class="container-fluid"
    [formGroup]="organizationFormGroup"
    *ngrxLet="{ isLoading: isLoading$ } as ctx">
    <div class="row">
      <div class="col-xs-12 col-md-6 margin-top-1">
        <sdx-input
          id="id"
          ngDefaultControl
          readonly="true"
          formControlName="id"
          label="{{ 'organization_form.label_input_id' | translate }}">
        </sdx-input>
      </div>

      <div class="col-xs-12 col-md-6 margin-top-1">
        <sdx-input
          id="name"
          ngDefaultControl
          formControlName="name"
          [attr.valid]="organizationFormGroup.controls['name'].valid"
          label="{{ 'organization_form.label_input_name' | translate }}">
        </sdx-input>
      </div>

      <div class="col-xs-12 col-md-6 margin-top-1">
        <sdx-select
          id="type"
          ngDefaultControl
          formControlName="type"
          label="{{ 'organization_form.label_input_type' | translate }}">
          <sdx-select-option
            *ngFor="let orgType of OrganizationType | keyvalue"
            [value]="orgType.key">
            {{ "data.organization_type." + orgType.key | lowercase | translate }}
          </sdx-select-option>
        </sdx-select>
      </div>

      <div class="col-xs-12 col-md-6 margin-top-1">
        <sdx-input
          label="{{ 'organization_form.label_input_gln' | translate }}"
          placeholder="{{ 'organization_form.placeholder_input_gln' | translate }}"
          formControlName="glns"
          ngDefaultControl
          [valid]="organizationFormGroup.controls['glns'].valid">
        </sdx-input>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-lg-12 margin-top-2">
        <sdx-button-group>
          <sdx-button
            label="{{ 'organization_form.label_button_cancel' | translate }}"
            theme="secondary"
            (click)="cancel()"
            *ngIf="showCancel"></sdx-button>
          <sdx-button
            *ngIf="showSave"
            label="{{ 'organization_form.label_button_save' | translate }}"
            theme="primary"
            (click)="save()"
            [disabled]="!organizationFormGroup.valid || !hasChanges"
            [loading]="ctx.isLoading"></sdx-button>
        </sdx-button-group>
      </div>
    </div>
  </div>
</sdx-card>
