import { createReducer, on } from "@ngrx/store";
import * as OrganizationActions from "../actions/organization.actions";
import { organizationAdapter, OrganizationState } from "../../models";

const initialState = {
  ...organizationAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    OrganizationActions.loadOrganizations,
    (state): OrganizationState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    OrganizationActions.loadOrganizationsSuccess,
    (state, { organizations }): OrganizationState =>
      organizationAdapter.setAll(organizations, { ...state, loading: false, loaded: true })
  ),
  on(
    OrganizationActions.loadOrganizationsFailure,
    (state): OrganizationState => ({ ...state, loading: false, loaded: false })
  ),

  on(
    OrganizationActions.updateOrganization,
    (state, { organization }): OrganizationState => ({ ...state, loading: true })
  ),
  on(
    OrganizationActions.updateOrganizationSuccess,
    (state, { organization }): OrganizationState =>
      organizationAdapter.setOne(organization, { ...state, loading: false })
  ),
  on(
    OrganizationActions.updateOrganizationFailure,
    (state): OrganizationState => ({ ...state, loading: false })
  ),

  on(
    OrganizationActions.createOrganization,
    (state, { organization }): OrganizationState => ({ ...state, loading: true })
  ),
  on(
    OrganizationActions.createOrganizationSuccess,
    (state, { organization }): OrganizationState =>
      organizationAdapter.addOne(organization, { ...state, loading: false })
  ),
  on(
    OrganizationActions.createOrganizationFailure,
    (state): OrganizationState => ({ ...state, loading: false })
  ),
  on(
    OrganizationActions.deleteOrganization,
    (state, { organizationId }): OrganizationState => ({ ...state, loading: true })
  ),
  on(
    OrganizationActions.deleteOrganizationSuccess,
    (state, { organizationId }): OrganizationState =>
      organizationAdapter.removeOne(organizationId, { ...state, loading: false })
  ),
  on(
    OrganizationActions.deleteOrganizationFailure,
    (state): OrganizationState => ({ ...state, loading: false })
  )
);

export const getOrganizationsLoading = (state: OrganizationState) => state.loading;
export const getOrganizationsLoaded = (state: OrganizationState) => state.loaded;
